import { createStore } from 'vuex'

export default createStore({
  state: {
  },
  getters: {
    getActiveNav (state) {
      return state.activeNav
    }
  },
  mutations: {
    setNav (state, data) {
      state.activeNav = data
    },
  },
  actions: {
    setActiveNav ({commit}, data) {
      commit('setNav', data)
    },
  },
  modules: {
  }
})
