const TokenKey = 'token'
const UserInfo = 'userInfo'
const Timestamp = 'timestamp'
// 获取当前的token
export function getToken () {
  return localStorage.getItem(TokenKey)
}

// 获取当前的用户信息
export function getUserInfo () {
  return localStorage.getItem(UserInfo)
}

// 获取上一次登录的时间戳
export function getTimestamp () {
  return localStorage.getItem(Timestamp)
}

// 设置当前的token
export function setToken (value) {
  localStorage.setItem(TokenKey, value)
}

// 删除当前的token
export function removeToken () {
  // localStorage.setItem(TokenKey, '')
  localStorage.clear()
}
